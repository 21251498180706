import React from 'react';
import Footer from '../Footer';
import classes from './Maintenance.module.scss';

const Maintenance = () => {
  return (
    <>
      <main className={classes.Maintenance}>
        <h2 className="color-primary">Maintenance in Progress</h2>
        <p>
          Sorry for the inconvenience. Our website is undergoing scheduled
          maintenance. <br /> We&apos;ll be back soon!
        </p>
      </main>
      <Footer />
    </>
  );
};

export default Maintenance;
